import api from '../../../api';
import { useEffect, useState } from 'react';
import {
  compareVersion,
  formatToMoney,
  truncateText
} from '../../../utils/helpers';
import { PlaceIcon } from '../../../components/SVGs';

import { ZakCardBox } from '../../../components/ZakCardBox';
import { ZakCardValueIndicator } from '../../../components/ZakCardValueIndicator';
import { useHistory, useLocation } from 'react-router';

import * as S from './styles';
import { sendAmplitudeData } from '../../../utils/amplitude';

type RestaurantCardProps = {
  id: string;
  name: string;
  state: string;
  hasInfra: boolean;
  dateFilter?: Date;
};

export function RestaurantCard({
  dateFilter = new Date(),
  ...props
}: RestaurantCardProps) {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (props.hasInfra) {
      getRevenueReportSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  async function getRevenueReportSummary() {
    if (props.hasInfra) {
      try {
        setLoading(true);
        const version = await api.getRestaurantVersion(props.id);
        if (compareVersion(version.version, '4.0.0') >= 0) {
          const data = await api.getRevenueReportSummary(
            props.id,
            'managerial',
            dateFilter,
            dateFilter
          );
          setValue(data.deliveryRevenue + data.tableRevenue);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <ZakCardBox
      onClick={() => {
        sendAmplitudeData('zakgestor_user_selectedrestaurant', {
          restaurandId: props.id
        });

        history.push(`${location.pathname}/restaurant/${props.id}`);
      }}
      cardTitle={
        <>
          <h3>{truncateText(props.name, 60)}</h3>
        </>
      }
    >
      <S.Info>
        <ZakCardValueIndicator
          label="R$"
          value={formatToMoney(value)}
          loading={loading}
        />

        <S.Place>
          {props.state}
          <PlaceIcon />
        </S.Place>
      </S.Info>
    </ZakCardBox>
  );
}
