import { useEffect, useMemo, useState } from 'react';
import api from '../../api';
import { CardLoader } from '../../components/ContentLoader';

import { ZakPageHeaderLayout } from '../../components/ZakPageHeaderLayout';
import { useStores } from '../../stores';
import { compareTwoStrings } from '../../utils/helpers';
import { notification } from '../../utils/notification';
import { OrganizationCard } from './OrganizationCard';

import * as S from './styles';

export function OrganizationList() {
  const { organizationStore, authStore } = useStores();

  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    notification('error.message', 'error');
  }, []);

  const filteredOrgs = useMemo(() => {
    return organizationStore.orgList.filter((org) =>
      compareTwoStrings(searchValue, org.name)
    );
  }, [searchValue, organizationStore.orgList]);

  useEffect(() => {
    if (!organizationStore.orgList.length) {
      getRestaurantsByOrganization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getRestaurantsByOrganization() {
    setLoading(true);
    try {
      const data = await api.getRestaurantsByOrganization();
      const orgListFilteredAndSorted =
        authStore.filterRestaurantsByOganization(data);
      organizationStore.changeOrgList(orgListFilteredAndSorted);
    } catch (error) {
      notification((error as Error).message, 'error');
    } finally {
      setLoading(false);
    }
  }

  return (
    <ZakPageHeaderLayout
      searchInputProps={{
        onChange: (e) => setSearchValue(e.target.value),
        value: searchValue
      }}
    >
      {loading ? (
        <CardLoader size={6} height={150} gap={12} />
      ) : (
        <S.CardList>
          {filteredOrgs.map((org) => (
            <OrganizationCard key={org.id} {...org} />
          ))}
        </S.CardList>
      )}
    </ZakPageHeaderLayout>
  );
}
