/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext } from 'react';
import api from '../api';

import { auth } from './auth';
import { organization } from './organization';

type Stores = {
  authStore: ReturnType<typeof auth>;
  organizationStore: ReturnType<typeof organization>;
};

const storesCtx = createContext<Stores>({
  authStore: {
    user: undefined,
    isLogged: false,
    login() {},
    logout() {},
    filterRestaurantsByOganization(
      __restaurantsByOrganization: api.RestaurantsByOrganization[]
    ) {
      return [];
    }
  },
  organizationStore: {
    currentOrg: null,
    changeCurrentOrg() {},
    orgList: [],
    changeOrgList() {},
    restaurantRangeFilter: 'today',
    setRestaurantRangeFilter() {},
    rangeDateDefaults: 'last7Days',
    changeRangeDateDefaults() {},
    shiftMode: 'managerial',
    changeShiftMode() {}
  }
});

export function useStores() {
  return useContext(storesCtx);
}

export function StoresProvider(props: React.PropsWithChildren<ReactNode>) {
  const authStore = auth();
  const organizationStore = organization();

  return (
    <storesCtx.Provider value={{ authStore, organizationStore }}>
      {props.children}
    </storesCtx.Provider>
  );
}
